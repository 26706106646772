<template>
  <ion-page>
    <!-- <page-header title="Alpha Victor" /> -->
    <av-header></av-header>
    <ion-content :scrollY="false" class="transparent">
      <div slot="fixed" class="fullheight fullwidth">
        <div class="fullheight fullwidth" :class="{ homeColor: !isScanning }" />
        <div class="flex vertical" :class="{ homeBackground: !isScanning }">
          <!-- Enter shortcode  -->
          <div class="mt2 pt2" style="width: 80%; height: 67px">
            <div class="flex p shortcodeContainer autowidth" v-if="!isScanning">
              <!-- <div class="absolute cd o5 pl boldFont" style="color: white" v-if="shortcode == ''">{{ t("enter_a_shortcode") }}</div> -->
              <ion-input
                class="shortcode"
                placeholder="Enter a shortcode"
                v-model="shortcode"
                :maxlength="8"
                type="text"
                v-on:keyup.enter="processShortcode"
                @ionFocus="shortcodeActive = true"
                @ionChange="shortcode = shortcode.toUpperCase()"
                @ionBlur="shortcodeActive = false"
              ></ion-input
              ><ion-icon :icon="arrowForwardOutline" id="shortcodeButton" @click="processShortcode" color="secondary" />
            </div>
          </div>

          <!-- SCANNER  -->
          <div
            class="fullwidth flex vertical autoheight nogap cd"
            style="padding-top: 60px"
            v-show="!shortcodeActive && isPlatform('cordova')"
          >
            <div class="square relative flex centered scanner">
              <img src="/img/frame/tl.png" class="top left secondary" />
              <img src="/img/frame/tr.png" class="top right secondary" />
              <img src="/img/frame/bl.png" class="bottom left secondary" />
              <img src="/img/frame/br.png" class="bottom right secondary" />

              <div v-if="isScanning" class="fs m2 tc">{{ t("point_your_camera_at_the_qr_code") }}</div>
              <div v-else class="fs m2 tc" @click="startScanning">{{ t("or_touch_here_if_you_have_a_qr_code") }}</div>
            </div>

            <div style="width: 50%; height: 50px" class="mt">
              <ion-button @click="stopScanning()" expand="block" class="bdl m0" v-if="isScanning">{{ t("cancel") }}</ion-button>
            </div>
          </div>

          <div class="fullwidth safe-bottom" v-if="!firebaseUser.isAnonymous">
            <div class="actionList p2 cd">
              <div class="action" v-if="nearestSpace" @click="showNearestSpace">
                <div class="cl flex left"><ion-icon :icon="navigateIcon" class="cl" />{{ nearestSpace.name }}</div>
                <div><ion-icon :icon="chevronForward" /></div>
              </div>
              <div class="action" @click="navigate('Recent')">
                <div class="flex left cl"><ion-icon :icon="navigateIcon" class="o0" />{{ $t("recently_viewed") }}</div>
                <div><ion-icon :icon="chevronForward" class="cl" /></div>
              </div>
              <div class="action" @click="navigate('Favourites')">
                <div class="flex left cl"><ion-icon :icon="navigateIcon" class="o0" />{{ $t("favourite_spaces") }}</div>
                <div><ion-icon :icon="chevronForward" class="cl" /></div>
              </div>
            </div>
          </div>

          <div v-else class="flex vertical autoheight spaceAround register pt">
            <img src="/img/logos/logo_white.png" style="height: 50px" />
            <div class="tc fs cl p2">
              Save favourite spaces and devices for quick access, browse available resources and more with a free Alpha Victor account.
            </div>
            <div class="fullwidth safe-bottom ph pb">
              <ion-button expand="block" class="bright" @click="navigate('LoginWithPassword')">Login or Register</ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { toastController, isPlatform, onIonViewWillEnter, onIonViewWillLeave, onIonViewDidLeave, getPlatforms } from "@ionic/vue";
import { ref, computed, onMounted, watch } from "vue";

import { useRouter } from "vue-router";
import { Keyboard } from "@capacitor/keyboard";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { arrowForwardOutline, chevronForward, navigate as navigateIcon } from "ionicons/icons";
import { useUserStore } from "@/stores/user";
import { useShortcodeStore } from "@/stores/shortcode";
import { useOrganisationStore } from "@/stores/organisation";
import { useBeaconStore } from "@/stores/beacons";
import { storeToRefs } from "pinia";
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic";
import { useI18n } from "vue-i18n";
import mixpanel from "mixpanel-browser";

export default {
  name: "Home",
  setup() {
    const router = useRouter();
    const isScanning = ref(false);
    const shortcode = ref("");
    const shortcodeActive = ref(false);
    const userStore = useUserStore();
    const organisationStore = useOrganisationStore();
    const shortcodeStore = useShortcodeStore();
    const beaconStore = useBeaconStore();
    const { organisation } = storeToRefs(organisationStore);
    const { user } = storeToRefs(userStore);
    const { nearestValidBeacon, nearestSpace, isRanging } = storeToRefs(beaconStore);
    const { shortcodes } = storeToRefs(shortcodeStore);
    const { firebaseUser } = storeToRefs(userStore);
    const { t } = useI18n();

    let scanTimer = null;

    onMounted(async () => {
      organisationStore.initialise();
    });

    onIonViewWillEnter(async () => {
      if (firebaseUser.value.isAnonymous || !isPlatform("cordova")) return;
      const status = await Diagnostic.getLocationAuthorizationStatus();
      if ([Diagnostic.permissionStatus.GRANTED, Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE].includes(status)) {
        beaconStore.start(true);
      }
    });

    onIonViewWillLeave(() => {
      if (isPlatform("cordova")) {
        beaconStore.stop();
      }
    });

    watch(firebaseUser, () => {
      if (!firebaseUser.value?.emailVerified && isRanging.value) beaconStore.stop();
    });

    const stopScanning = () => {
      clearTimeout(scanTimer);
      isScanning.value = false;
      setTimeout(() => {
        BarcodeScanner.stopScan();
        BarcodeScanner.showBackground();
      }, 500);
    };

    const decode = async (data) => {
      let slug = data.split(process.env.VUE_APP_DOMAIN).pop();
      mixpanel.track("Code Received", { slug });
      if (slug) {
        stopScanning();
        Haptics.impact({ style: ImpactStyle.Light });
        router.push({ path: slug });
      }
    };

    const startScanning = async () => {
      if (!isPlatform("mobile")) return;
      mixpanel.track("Scan Started");

      if (scanTimer) clearTimeout(scanTimer);

      const permission = await BarcodeScanner.checkPermission();
      if (permission.neverAsked) await BarcodeScanner.checkPermission({ force: true });

      isScanning.value = true;
      await BarcodeScanner.hideBackground();
      isScanning.value = true;
      scanTimer = setTimeout(() => {
        stopScanning();
      }, 5000);

      const result = await BarcodeScanner.startScan();

      if (result.hasContent) {
        decode(result.content);
      }
    };

    const canScan = computed(() => {
      return isPlatform("mobile");
    });

    const navigate = (to) => {
      router.push({ name: to });
    };

    const showNearestSpace = () => {
      const organisationID = nearestValidBeacon.value?.used?.organisationID;
      const locationID = nearestValidBeacon.value?.used?.locationID;
      const floorID = nearestValidBeacon.value?.used?.floorID;
      const spaceID = nearestValidBeacon.value?.used?.spaceID;
      router.push({ name: "Space", params: { organisationID, locationID, floorID, spaceID } });
    };

    const processShortcode = async () => {
      mixpanel.track("Shortcode Entered", { shortcode: shortcode.value });

      if (isPlatform("cordova")) Keyboard.hide();
      shortcode.value = shortcode.value.toUpperCase();
      const code = shortcode.value;
      // if (code.length != 6) {
      //   const toast = await toastController.create({
      //     message: `${code} is not a valid shortcode.`,
      //     duration: 2000,
      //   });
      //   toast.present();
      //   shortcode.value = "";
      //   return;
      // }

      const shortcodeDoc = shortcodes.value[code];

      const rejectAccess =
        !shortcodeDoc ||
        (userStore.data.firebaseUser?.isAnonymous && !shortcodeDoc.allowAnonymous) ||
        (!shortcodeDoc.allowAnonymous && user.value?.organisations?.includes(organisation.id));

      if (rejectAccess) {
        const toast = await toastController.create({
          message: `Could not find shortcode ${code}.`,
          duration: 2000,
        });
        toast.present();
        shortcode.value = "";
        return;
      }

      if (userStore.data.firebaseUser?.isAnonymous && !shortcodeDoc.allowAnonymous) {
        const toast = await toastController.create({
          message: `Could not find shortcode ${code}.`,
          duration: 2000,
        });
        toast.present();
        shortcode.value = "";
        return;
      }

      const registered = firebaseUser.value?.emailVerified;

      switch (shortcodeDoc.type) {
        case "location":
          router.push({
            name: registered ? "Location" : "AnonymousLocation",
            params: {
              organisationID: shortcodeDoc.organisationID,
              locationID: shortcodeDoc.locationID,
            },
          });
          break;
        case "space":
          router.push({
            name: "Space",
            params: {
              organisationID: shortcodeDoc.organisationID,
              locationID: shortcodeDoc.locationID,
              floorID: shortcodeDoc.floorID,
              spaceID: shortcodeDoc.spaceID,
            },
          });
          break;
        default:
          break;
      }
      shortcode.value = "";
    };

    return {
      // variables
      firebaseUser,
      isScanning,
      canScan,
      shortcode,
      shortcodeActive,
      nearestValidBeacon,
      nearestSpace,
      // methods
      startScanning,
      stopScanning,
      isPlatform,
      processShortcode,
      navigate,
      showNearestSpace,
      t,
      // icons
      arrowForwardOutline,
      chevronForward,
      navigateIcon,
    };
  },
};
</script>

<style scoped>
ion-content {
  background: var(--ion-background-color);
}
.masked-container {
  width: 100%;
  height: 100%;
  --background: url("img/transparent.png");
}

.scannerOff:before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: var(--theme-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  filter: grayscale(100%) brightness(50%);
}

.scannerButton {
  position: absolute;
  top: 70%;
  width: 100%;
  padding: 0% 10%;
  margin: 0px;
}

.scannerOff,
.scannerOn,
.scannerButton {
  opacity: 0;
  transition: opacity 0.5s;
}

.scannerOff.visible,
.scannerOn.visible,
.scannerButton.visible {
  opacity: 1;
  transition: opacity 0.5s;
}

.scannerLabel {
  position: absolute;
  top: 25%;
  transform: translateY(-50%);
  left: 20%;
  width: 60%;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 5px;
  pointer-events: all !important;
}

.frame {
  position: absolute;
  height: 20px;
}

.frame.top {
  top: 10%;
}

.frame.left {
  left: 20%;
}

.frame.right {
  right: 20%;
}

.frame.bottom {
  bottom: 60%;
}

#shortcodeContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 45%;
  left: 10%;
  width: 80%;
}

#shortcode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--ion-color-dark);
  font-size: 2em;
  padding: 0px 20px;
}

#shortcodeHint {
  position: absolute;
  width: calc(100% - 40px);
  text-align: center;
  font-size: 1rem;
}

#noCode {
  position: relative;
}

.visible {
  opacity: 0.6;
  transition: opacity 0.3s ease-in;
}

.hidden {
  opacity: 0;
  transition: opacity 0.1s ease-in;
}

#shortcodeButton {
  font-size: 1rem;
}

#register {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  bottom: 0px;
  width: 100%;
  height: 35%;
  padding: 40px;
  background-color: var(--ion-color-light);
  opacity: 0;
  animation: 0.3s fadeIn;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  border-radius: 50px 50px 0px 0px;
}

#register ion-button {
  margin-bottom: var(safe-area-inset-bottom);
  font-size: 1rem;
}

#welcome {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 25%;
  opacity: 0;
  animation: 0.3s fadeIn;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.welcomeTitle {
  color: var(--ion-color-dark);
  font-family: Title;
  font-size: 1.8em;
}

.welcomeDescription {
  text-align: center;
  color: var(--ion-color-dark);
  font-size: 0.8em;
}

.welcomeButton {
  width: 80%;
  height: 2.4em;
  font-size: 1.4em;
}

.actionList {
  width: 100%;
  padding: 20px;
}
/* 
.action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-family: Title;
  border-bottom: 1px solid rgba(var(--ion-color-dark-rgb), 0.5);
  padding: 20px 10px;
  font-size: 0.8em;
} */

.action:first-child {
  border-top: 1px solid rgba(var(--ion-color-dark-rgb), 0.5);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.shortcode {
  color: var(--ion-color-dark-contrast);
  font-family: Bold;
  --padding-start: 10px;
}

.shortcodeContainer {
  background: var(--ion-color-dark);
  border: 1px solid rgba(var(--ion-color-dark-contrast-rgb), 0.5);
  border-radius: 5px;
}

.showLines {
  background: url("@/theme/backgrounds/lines-no-logo.svg");
  background-size: cover;
}

ion-button.bright {
  --background: var(--ion-color-menu);
  color: var(--ion-color-menu-contrast);
}
</style>
