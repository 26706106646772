import { createApp, h } from "vue";
import Main from "./Main.vue";
import router from "./router";
import "./registerServiceWorker";
import { auth } from "@/composables/firebase";
import VueGoogleMaps from "@fawmi/vue-google-maps";
const Package = require("../package.json");

/* Sentry  logging */

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
//import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
// import "@ionic/vue/css/padding.css";
// import "@ionic/vue/css/float-elements.css";
// import "@ionic/vue/css/text-alignment.css";
// import "@ionic/vue/css/text-transformation.css";
// import "@ionic/vue/css/flex-utils.css";
// import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/uncoded.css";

/* Ionic Components */
import {
  IonBackdrop,
  IonButtons,
  IonCheckbox,
  IonicVue,
  IonMenuButton,
  IonSpinner,
  IonPage,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonList,
  IonNote,
  IonHeader,
  IonToolbar,
  IonButton,
  IonFooter,
  IonGrid,
  IonCol,
  IonRow,
  IonTitle,
  IonMenu,
  IonListHeader,
  IonItemGroup,
  IonThumbnail,
  IonToggle,
  IonReorderGroup,
  IonReorder,
  IonInput,
  IonTextarea,
  IonAccordion,
  IonAccordionGroup,
  IonRadio,
  IonRadioGroup,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonChip,
  IonNav,
  IonBadge,
  IonSelect,
  IonSelectOption,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonDatetime,
  IonDatetimeButton,
} from "@ionic/vue";
import Wait from "@/components/Wait.vue";
// import PageHeader from "@/components/PageHeader";
import AvHeader from "@/components/AvHeader";
import NavFooter from "@/components/NavFooter";
import EditHeader from "@/components/EditHeader.vue";
import ViewHeader from "@/components/ViewHeader.vue";
import { createPinia } from "pinia";

import PrimeVue from "primevue/config";
import "./assets/theme.css";
import "primevue/resources/primevue.min.css"; // core css
import "primeicons/primeicons.css";
import Tree from "primevue/tree";
import Checkbox from "primevue/tristatecheckbox";
import Message from "primevue/message";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import Panel from "primevue/panel";
import SplitButton from "primevue/splitbutton";
import ConfirmationService from "primevue/confirmationservice";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";
import Toolbar from "primevue/toolbar";
import Card from "primevue/card";
import ToggleButton from "primevue/togglebutton";
import ConfirmPopup from "primevue/confirmpopup";

import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { useUserStore } from "./stores/user";
import i18n from "./i18n";
import mixpanel from "mixpanel-browser";
const store = createPinia();
const app = createApp({
  render: () => h(Main),
});
const mode = process.env.VUE_APP_MODE;
mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, { host: "api-eu.mixpanel.com", debug: true, ignore_dnt: true });

// if (mode == "production") {
//   Sentry.init({
//     app,
//     dsn: "https://a757921ec4be47249a96d71b8cc2242f@o1113233.ingest.sentry.io/4504077800177664",
//     release: `${Package.name}@${Package.version}`,
//     environment: mode,
//     dist: "1",
//     integrations: [
//       new Integrations.BrowserTracing({
//         //routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracingOrigins: ["localhost", "alphavictor.web.app", "https://www.alphavictor.com", "https://dev.alphavictor.com", /^\//],
//       }),
//       new Sentry.Replay(),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.1,
//   });
// }

onAuthStateChanged(auth, async () => {
  if (!auth.currentUser) {
    await signInAnonymously(auth);
  }

  mixpanel.identify(auth.currentUser?.email || auth.currentUser?.uid);

  if (auth.currentUser?.email) mixpanel.track("User Authenticated", { email: auth.currentUser.email });

  // Sentry.setUser({ email: auth.currentUser?.email || "Anonymous" });
  // Sentry.addBreadcrumb({ message: `Logged in`, data: auth.currentUser });

  const userStore = useUserStore();
  userStore.updateUser();
});

app
  .use(IonicVue, { swipeBackEnabled: false })
  .use(router)
  .use(store)
  .use(PrimeVue, { unstyled: false })
  .use(ConfirmationService)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    },
  })
  .use(i18n);

app.component("IonPage", IonPage);
app.component("IonContent", IonContent);
app.component("IonIcon", IonIcon);
app.component("IonItem", IonItem);
app.component("IonLabel", IonLabel);
app.component("IonSegment", IonSegment);
app.component("IonSegmentButton", IonSegmentButton);
app.component("IonCard", IonCard);
app.component("IonCardContent", IonCardContent);
app.component("IonCardHeader", IonCardHeader);
app.component("IonCardTitle", IonCardTitle);
app.component("IonCardSubtitle", IonCardSubtitle);
app.component("IonList", IonList);
app.component("IonNote", IonNote);
app.component("IonHeader", IonHeader);
app.component("IonButton", IonButton);
app.component("IonMenuButton", IonMenuButton);
app.component("IonButtons", IonButtons);
app.component("IonFooter", IonFooter);
app.component("IonToolbar", IonToolbar);
app.component("IonTitle", IonTitle);
app.component("IonMenu", IonMenu);
app.component("IonListHeader", IonListHeader);
app.component("IonItemGroup", IonItemGroup);
app.component("IonThumbnail", IonThumbnail);
app.component("IonToggle", IonToggle);
app.component("IonReorderGroup", IonReorderGroup);
app.component("IonReorder", IonReorder);
app.component("IonInput", IonInput);
app.component("IonTextarea", IonTextarea);
app.component("IonGrid", IonGrid);
app.component("IonCol", IonCol);
app.component("IonRow", IonRow);
app.component("IonAccordionGroup", IonAccordionGroup);
app.component("IonAccordion", IonAccordion);
app.component("IonRadio", IonRadio);
app.component("IonRadioGroup", IonRadioGroup);
app.component("IonBackdrop", IonBackdrop);
app.component("IonModal", IonModal);
app.component("IonCheckbox", IonCheckbox);
app.component("IonSpinner", IonSpinner);
app.component("IonChip", IonChip);
app.component("IonNav", IonNav);
app.component("IonBadge", IonBadge);
app.component("IonSelect", IonSelect);
app.component("IonSelectOption", IonSelectOption);
app.component("IonItemSliding", IonItemSliding);
app.component("IonItemOptions", IonItemOptions);
app.component("IonItemOption", IonItemOption);
app.component("IonDatetimeButton", IonDatetimeButton);
app.component("IonDatetime", IonDatetime);
app.component("Wait", Wait);
app.component("EditHeader", EditHeader);
app.component("ViewHeader", ViewHeader);
app.component("AvHeader", AvHeader);
app.component("NavFooter", NavFooter);
app.component("IonRefresher", IonRefresher);
app.component("IonRefresherContent", IonRefresherContent);
app.component("Tree", Tree);
app.component("Checkbox", Checkbox);
app.component("Message", Message);
app.component("Textarea", Textarea);
app.component("Button", Button);
app.component("Panel", Panel);
app.component("SplitButton", SplitButton);
app.component("Dropdown", Dropdown);
app.component("MultiSelect", MultiSelect);
app.component("InputText", InputText);
app.component("FloatLabel", FloatLabel);
app.component("Toolbar", Toolbar);
app.component("Card", Card);
app.component("ConfirmPopup", ConfirmPopup);
app.component("ToggleButton", ToggleButton);
// app.component("", );

router.isReady().then(() => {
  app.mount("#app");
});
