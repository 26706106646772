import { createRouter, createWebHistory } from "@ionic/vue-router";
import { useUserStore } from "@/stores/user";
import { useShortcodeStore } from "@/stores/shortcode";
import Organisation from "@/views/view/Organisation";
import Location from "@/views/view/Location";
import Floor from "@/views/view/Floor";
import Space from "@/views/view/Space";
// import AnonymousSpace from "@/views/view/AnonymousSpace";

import Article from "@/views/view/Space/Article";
import Video from "@/views/view/Space/Video";
import Device from "@/views/view/Space/Device";

import SpaceHowTo from "@/views/view/Space/HowTo";
import SpaceInThisSpace from "@/views/view/Space/InThisSpace";
import SpaceChecklist from "@/views/view/Space/Checklist";
import SpaceLookAround from "@/views/view/Space/LookAround";
import SpaceIssues from "@/views/view/Space/Issues";
import SpaceAnonymousIssues from "@/views/view/Space/Anonymous/Issues";
import SpaceIssue from "@/views/view/Space/Issue";
import SpaceDocuments from "@/views/view/Space/Documents";
import SpaceDocument from "@/views/utility/Document";
import SpaceGallery from "@/views/view/Space/Gallery";
import SpaceReportIssue from "@/views/view/Space/ReportIssue";
import SpaceGiveFeedback from "@/views/view/Space/GiveFeedback";
import DeviceDocuments from "@/views/view/Space/DeviceDocuments";

import SupportChecklists from "@/views/view/Support/Checklists";
import SupportReports from "@/views/view/Support/Reports";

import EditOrganisation from "@/views/edit/EditOrganisation";
import EditOrganisationDetails from "@/views/edit/Organisation/Details";
import EditOrganisationAddress from "@/views/edit/Organisation/Address";
import EditOrganisationTheme from "@/views/edit/Organisation/Theme";
import EditOrganisationSpaceTypes from "@/views/edit/Organisation/SpaceTypes";
import EditOrganisationSpaceFeatures from "@/views/edit/Organisation/SpaceFeatures";
import EditOrganisationDocumentTypes from "@/views/edit/Organisation/DocumentTypes";
import EditOrganisationBackground from "@/views/edit/Organisation/Background";
import EditOrganisationLogo from "@/views/edit/Organisation/Logo";
import EditOrganisationDecisionTree from "@/views/edit/Organisation/DecisionTree";
import EditOrganisationArticle from "@/views/edit/Organisation/Article";
import EditOrganisationVideo from "@/views/edit/Organisation/Video";
import EditOrganisationIntegrations from "@/views/edit/Organisation/Integrations";
import EditOrganisationChecklists from "@/views/edit/Organisation/Checklists";
import EditChecklist from "@/views/edit/Organisation/Checklist";

import EditOrganisationUbiqisense from "@/views/edit/Organisation/Integrations/Ubiqisense";
import EditOrganisationPronestor from "@/views/edit/Organisation/Integrations/Pronestor";

import EditModel from "@/views/edit/EditModel";

import EditLocation from "@/views/edit/EditLocation";
import EditLocationDetails from "@/views/edit/Location/Details";
import EditLocationAddress from "@/views/edit/Location/Address";
import EditLocationPhotos from "@/views/edit/Location/Photos";
import EditLocationExport from "@/views/edit/Location/Export";
import EditLocationFloors from "@/views/edit/Location/Floors";
import EditLocationDeviceDefaults from "@/views/edit/Location/DeviceDefaults";
import EditLocationSpaceDefaults from "@/views/edit/Location/SpaceDefaults";

import EditFloor from "@/views/edit/EditFloor";
import EditFloorDetails from "@/views/edit/Floor/Details";
import EditFloorSpaces from "@/views/edit/Floor/Spaces";

import EditSpace from "@/views/edit/EditSpace";
import EditSpaceDetails from "@/views/edit/Space/Details";
import EditSpaceDecisionTree from "@/views/edit/Space/DecisionTree";
import EditSpaceChecklist from "@/views/edit/Space/Checklist";
import EditSpaceFeatures from "@/views/edit/Space/Features";
import EditSpaceEquipment from "@/views/edit/Space/Equipment";
import EditSpacePanorama from "@/views/edit/Space/Panorama";
import EditSpaceDocuments from "@/views/edit/Space/Documents";
import EditSpacePhotos from "@/views/edit/Space/Photos";
import EditSpaceBeacon from "@/views/edit/Space/Beacon";
import EditSpaceQR from "@/views/edit/Space/QR";
import EditSpaceIntegrations from "@/views/edit/Space/Integrations";

import EditSpaceUbiqisense from "@/views/edit/Space/Integrations/Ubiqisense";
import EditSpacePronestor from "@/views/edit/Space/Integrations/Pronestor";

import ListOrganisations from "@/views/list/ListOrganisations";
import ListAssetTypes from "@/views/list/ListAssetTypes";
import ListManufacturers from "@/views/list/ListManufacturers";
import ListModels from "@/views/list/ListModels";
import ListLocations from "@/views/list/ListLocations";
import ListDecisionTrees from "@/views/list/ListDecisionTrees";

import SpaceReport from "@/views/edit/Reports/Spaces";

import SendEmail from "@/views/view/Space/Email.vue";

import Home from "@/views/home/Home.vue";
import AppHome from "@/views/home/AppHome.vue";

import StaticHome from "@/views/home/StaticHome.vue";

import Download from "@/views/utility/Download.vue";
import EmbedPDF from "@/views/utility/EmbedPDF.vue";
import Favourites from "@/views/utility/Favourites.vue";
import Recent from "@/views/utility/Recent.vue";
import Account from "@/views/utility/Account.vue";
import DeleteAccount from "@/views/utility/DeleteAccount.vue";
import Settings from "@/views/utility/Settings.vue";
import SelectOrganisation from "@/views/utility/SelectOrganisation.vue";
import About from "@/views/utility/About.vue";
import Terms from "@/views/utility/Terms.vue";
import Contact from "@/views/utility/Contact.vue";
import NoAccess from "@/views/utility/NoAccess.vue";
import LegalNotices from "@/views/utility/LegalNotices.vue";
import Onboarding from "@/views/utility/Onboarding.vue";
import NotFound from "@/views/utility/404.vue";
import CloseMe from "@/views/utility/CloseMe.vue";
import Upgrade from "@/views/utility/Upgrade.vue";
import Admin from "@/views/utility/Admin.vue";

import Login from "@/views/auth/Login.vue";
import LoginWithPassword from "@/views/auth/LoginWithPassword.vue";
import Register from "@/views/auth/Register.vue";
import Reminder from "@/views/auth/Reminder.vue";
import VerifyEmail from "@/views/auth/VerifyEmail.vue";
import Auth from "@/views/auth/Auth.vue";
import ResetPassword from "@/views/auth/responses/ResetPassword.vue";
import ValidateEmail from "@/views/auth/responses/ValidateEmail.vue";
import MagicLink from "@/views/auth/responses/MagicLink.vue";

import { isPlatform } from "@ionic/vue";

import { setScreen } from "@/composables/analytics";
import { computed } from "vue";
import { db, checkVersion } from "@/composables/firebase";
import { doc, getDoc } from "firebase/firestore";
import { logScan } from "../composables/analytics";

const canInstall = computed(() => {
  return (isPlatform("ios") || isPlatform("android")) && isPlatform("mobileweb");
});

const isMobile = computed(() => {
  return isPlatform("ios") || isPlatform("android");
});

const checkRole = (role) => {
  const userStore = useUserStore();
  return new Promise(checkForRoles);
  function checkForRoles(resolve) {
    if (userStore.data?.user?.roles) {
      resolve(userStore.data?.user?.roles?.includes(role));
    } else {
      setTimeout(checkForRoles.bind(this, resolve), 50);
    }
  }
};

const checkAnonymous = (role) => {
  const userStore = useUserStore();
  return new Promise(check);
  function check(resolve) {
    if (userStore.data?.firebaseUser) {
      resolve(userStore.data.firebaseUser.isAnonymous);
    } else {
      setTimeout(check.bind(this, resolve), 50);
    }
  }
};

const checkShortcodeAllowed = (shortcode) => {
  if (shortcode.allowAnonymous) return true;

  const userStore = useUserStore();
  return new Promise(checkAllowed);

  function checkAllowed(resolve) {
    if (userStore.data?.firebaseUser && (userStore.data.firebaseUser.isAnonymous || userStore.data?.userLoaded)) {
      if (userStore.data.firebaseUser.isAnonymous) {
        resolve(false);
        return;
      }
      const userOrganisations = userStore.data.user.organisations;
      if (userOrganisations?.includes(shortcode.organisationID)) {
        resolve(true);
        return;
      }

      resolve(false);
    } else {
      setTimeout(checkAllowed.bind(this, resolve), 50);
    }
  }
};

const checkOrganisationAdmin = async (to, _, next) => {
  const role = "organisationAdmin";
  if (await checkRole(role)) {
    next();
  } else {
    next({ name: "NoAccess" });
  }
};

const checkDecisionTreeAdmin = async (to, _, next) => {
  const role = "decisionTreeAdmin";
  if (await checkRole(role)) {
    next();
  } else {
    next({ name: "NoAccess" });
  }
};

const checkManufacturerAdmin = async (to, _, next) => {
  const role = "manufacturerAdmin";
  if (await checkRole(role)) {
    next();
  } else {
    next({ name: "NoAccess" });
  }
};

const checkSpaceAdmin = async (to, _, next) => {
  const role = "spaceAdmin";
  if (await checkRole(role)) {
    next();
  } else {
    next({ name: "NoAccess" });
  }
};

const checkHome = async (to, from, next) => {
  //If using native app, go to app home regardless of login state
  if (isPlatform("cordova")) {
    if (to.name == "AppHome") {
      next();
    } else {
      next({ name: "AppHome" });
    }
  }

  //If logged in, determine if user is going to correct homepage
  else if (isMobile.value && to.name == "Home") {
    next({ name: "AppHome" });
  } else if (!isMobile.value && to.name == "AppHome") {
    next({ name: "Home" });
  } else {
    next();
  }
};

const processLink = (to, _, next) => {
  const components = to.path.split("/");
  const linkID = components[2];
  const linkDocRef = doc(db, "links", linkID);
  getDoc(linkDocRef).then((linkDoc) => {
    const link = linkDoc.data();
    const params = { organisationID: link.organisationID, locationID: link.locationID, floorID: link.floorID, spaceID: link.spaceID };
    if (canInstall.value) {
      next({
        name: "DownloadSpace",
        params: params,
      });
    } else {
      next({
        name: "Space",
        params: params,
      });
    }
  });
};

const processQR = async (to, _, next) => {
  const components = to.path.split("/");
  const shortcode = components[2];
  const shortcodeStore = useShortcodeStore();
  await shortcodeStore.shortcodesLoaded();
  const shortcodes = shortcodeStore.shortcodes;
  const doc = shortcodes[shortcode.toUpperCase()];

  logScan(shortcode, doc.organisationID, doc.locationID, doc.floorID, doc.spaceID);

  const allowed = await checkShortcodeAllowed(doc);

  if (!allowed) {
    next({ name: "Home" });
    return;
  }

  if (!doc) {
    next({ name: "Home" });
  }

  if (!doc.allowAnonymous) {
    const userIsAnonymous = await checkAnonymous();
    if (userIsAnonymous) {
      next({ name: "Home" });
      return;
    }
  }

  if (canInstall.value) {
    switch (doc.type) {
      case "space":
        next({
          name: "DownloadSpace",
          params: { organisationID: doc.organisationID, locationID: doc.locationID, floorID: doc.floorID, spaceID: doc.spaceID },
        });
        break;
      default:
        next();
    }
  } else {
    switch (doc.type) {
      case "space":
        next({
          name: "Space",
          params: { organisationID: doc.organisationID, locationID: doc.locationID, floorID: doc.floorID, spaceID: doc.spaceID },
        });
        break;
      default:
        next();
    }
  }
};

const routes = [
  {
    path: "",
    name: "Root",
    beforeEnter: (to, _, next) => next({ name: "Home" }),
    component: Home,
  },
  {
    path: "/welcome",
    name: "StaticHome",
    component: StaticHome,
  },
  {
    path: "/link/:id",
    beforeEnter: processLink,
    component: Home,
  },
  {
    path: "/qr/:id",
    beforeEnter: processQR,
    component: Home,
  },
  {
    path: "/home",
    name: "Home",
    beforeEnter: checkHome,
    component: Home,
  },
  {
    path: "/appHome",
    name: "AppHome",
    beforeEnter: checkHome,
    component: AppHome,
  },
  // View links
  {
    path: "/organisation/view",
    name: "Organisation",
    component: Organisation,
  },
  {
    path: "/locations/view/:organisationID/:locationID",
    name: "Location",
    component: Location,
  },
  {
    path: "/floor/view/:organisationID/:locationID/:floorID",
    name: "Floor",
    component: Floor,
  },
  {
    path: "/space/view/:organisationID/:locationID/:floorID/:spaceID",
    name: "Space",
    component: Space,
  },
  {
    path: "/email/:organisationID/:locationID/:floorID/:spaceID",
    name: "Email",
    component: SendEmail,
  },
  // {
  //   path: "/space/anonymous/view/:organisationID/:locationID/:floorID/:spaceID",
  //   name: "AnonymousSpace",
  //   component: AnonymousSpace,
  // },
  {
    path: "/space/howTo/:organisationID/:locationID/:floorID/:spaceID/:decisionTreeID",
    name: "SpaceHowTo",
    component: SpaceHowTo,
  },
  {
    path: "/support/checklists/:organisationID",
    name: "SupportChecklists",
    component: SupportChecklists,
  },
  {
    path: "/support/reports",
    name: "SupportReports",
    component: SupportReports,
  },
  {
    path: "/space/inThisSpace/:organisationID/:locationID/:floorID/:spaceID",
    name: "SpaceInThisSpace",
    component: SpaceInThisSpace,
  },
  {
    path: "/space/checklist/:organisationID/:locationID/:floorID/:spaceID/:checklistID",
    name: "SpaceChecklist",
    component: SpaceChecklist,
  },
  {
    path: "/space/lookAround/:organisationID/:locationID/:floorID/:spaceID",
    name: "SpaceLookAround",
    component: SpaceLookAround,
  },

  {
    path: "/space/lookAtDevice/:organisationID/:locationID/:floorID/:spaceID/:deviceID",
    name: "SpaceLookAroundWithDevice",
    component: SpaceLookAround,
  },
  {
    path: "/space/issues/:organisationID/:locationID/:floorID/:spaceID",
    name: "SpaceIssues",
    component: SpaceIssues,
  },
  {
    path: "/space/anonymous/issues/:organisationID/:locationID/:floorID/:spaceID",
    name: "SpaceAnonymousIssues",
    component: SpaceAnonymousIssues,
  },
  {
    path: "/space/issue/:organisationID/:issueID",
    name: "SpaceIssue",
    component: SpaceIssue,
  },
  {
    path: "/space/gallery/:organisationID/:locationID/:floorID/:spaceID",
    name: "SpaceGallery",
    component: SpaceGallery,
  },
  {
    path: "/space/documents/:organisationID/:locationID/:floorID/:spaceID",
    name: "SpaceDocuments",
    component: SpaceDocuments,
  },
  {
    path: "/space/document/:description/:path",
    name: "SpaceDocument",
    component: SpaceDocument,
  },
  {
    path: "/space/reportIssue/:organisationID/:locationID/:floorID/:spaceID",
    name: "SpaceReportIssue",
    component: SpaceReportIssue,
  },
  {
    path: "/space/giveFeedback/:organisationID/:locationID/:floorID/:spaceID",
    name: "SpaceGiveFeedback",
    component: SpaceGiveFeedback,
  },
  {
    path: "/device/documents/:organisationID/:locationID/:floorID/:spaceID/:modelID",
    name: "DeviceDocuments",
    component: DeviceDocuments,
  },
  {
    path: "/article/:organisationID/:articleID",
    name: "Article",
    component: Article,
  },
  {
    path: "/video/:organisationID/:videoID/:vimeoID/:hash",
    name: "Video",
    component: Video,
  },
  {
    path: "/device/:organisationID/:locationID/:floorID/:spaceID/:deviceID",
    name: "Device",
    component: Device,
  },

  // Edit links
  {
    path: "/organisation/edit/:organisationID",
    name: "EditOrganisation",
    component: EditOrganisation,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/details/edit/:organisationID",
    name: "EditOrganisationDetails",
    component: EditOrganisationDetails,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/address/edit/:organisationID",
    name: "EditOrganisationAddress",
    component: EditOrganisationAddress,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/theme/edit/:organisationID",
    name: "EditOrganisationTheme",
    component: EditOrganisationTheme,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/types/edit/:organisationID",
    name: "EditOrganisationSpaceTypes",
    component: EditOrganisationSpaceTypes,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/features/edit/:organisationID",
    name: "EditOrganisationSpaceFeatures",
    component: EditOrganisationSpaceFeatures,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/integrations/edit/:organisationID",
    name: "EditOrganisationIntegrations",
    component: EditOrganisationIntegrations,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/checklists/edit/:organisationID",
    name: "EditOrganisationChecklists",
    component: EditOrganisationChecklists,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/checklists/edit/:organisationID/:checklistID",
    name: "EditChecklist",
    component: EditChecklist,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/integrations/ubiqisense/edit/:organisationID",
    name: "EditOrganisationUbiqisense",
    component: EditOrganisationUbiqisense,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/integrations/pronestor/edit/:organisationID",
    name: "EditOrganisationPronestor",
    component: EditOrganisationPronestor,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/documentTypes/edit/:organisationID",
    name: "EditOrganisationDocumentTypes",
    component: EditOrganisationDocumentTypes,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/logo/edit/:organisationID",
    name: "EditOrganisationLogo",
    component: EditOrganisationLogo,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/background/edit/:organisationID",
    name: "EditOrganisationBackground",
    component: EditOrganisationBackground,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/decisionTree/edit/:organisationID/:decisionTreeID",
    name: "EditOrganisationDecisionTree",
    component: EditOrganisationDecisionTree,
    beforeEnter: checkDecisionTreeAdmin,
  },
  {
    path: "/organisation/article/edit/:organisationID/:articleID",
    name: "EditOrganisationArticle",
    component: EditOrganisationArticle,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/organisation/video/edit/:organisationID/:videoID",
    name: "EditOrganisationVideo",
    component: EditOrganisationVideo,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/model/edit/:modelID",
    name: "EditModel",
    component: EditModel,
    beforeEnter: checkManufacturerAdmin,
  },
  {
    path: "/location/edit/:organisationID/:locationID",
    name: "EditLocation",
    component: EditLocation,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/location/details/edit/:organisationID/:locationID",
    name: "EditLocationDetails",
    component: EditLocationDetails,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/location/address/edit/:organisationID/:locationID",
    name: "EditLocationAddress",
    component: EditLocationAddress,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/location/photos/edit/:organisationID/:locationID",
    name: "EditLocationPhotos",
    component: EditLocationPhotos,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/location/export/edit/:organisationID/:locationID",
    name: "EditLocationExport",
    component: EditLocationExport,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/location/floors/edit/:organisationID/:locationID",
    name: "EditLocationFloors",
    component: EditLocationFloors,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/location/device_defaults/edit/:organisationID/:locationID",
    name: "EditLocationDeviceDefaults",
    component: EditLocationDeviceDefaults,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/location/space_defaults/edit/:organisationID/:locationID",
    name: "EditLocationSpaceDefaults",
    component: EditLocationSpaceDefaults,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/floor/edit/:organisationID/:locationID/:floorID",
    name: "EditFloor",
    component: EditFloor,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/floor/details/edit/:organisationID/:locationID/:floorID",
    name: "EditFloorDetails",
    component: EditFloorDetails,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/floor/spaces/edit/:organisationID/:locationID/:floorID",
    name: "EditFloorSpaces",
    component: EditFloorSpaces,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpace",
    component: EditSpace,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/details/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpaceDetails",
    component: EditSpaceDetails,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/documents/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpaceDocuments",
    component: EditSpaceDocuments,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/features/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpaceFeatures",
    component: EditSpaceFeatures,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/panorama/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpacePanorama",
    component: EditSpacePanorama,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/decisionTree/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpaceDecisionTree",
    component: EditSpaceDecisionTree,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/checklist/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpaceChecklist",
    component: EditSpaceChecklist,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/photos/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpacePhotos",
    component: EditSpacePhotos,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/equipment/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpaceEquipment",
    component: EditSpaceEquipment,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/beacon/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpaceBeacon",
    component: EditSpaceBeacon,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/integrations/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpaceIntegrations",
    component: EditSpaceIntegrations,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/integrations/ubiqisense/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpaceUbiqisense",
    component: EditSpaceUbiqisense,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/integrations/pronestor/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpacePronestor",
    component: EditSpacePronestor,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/space/qr/edit/:organisationID/:locationID/:floorID/:spaceID",
    name: "EditSpaceQR",
    component: EditSpaceQR,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    beforeEnter: checkOrganisationAdmin,
  },
  //List views
  {
    path: "/organisations/list",
    name: "ListOrganisations",
    component: ListOrganisations,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/assetTypes/list",
    name: "ListAssetTypes",
    component: ListAssetTypes,
    beforeEnter: checkManufacturerAdmin,
  },
  {
    path: "/manufacturers/list",
    name: "ListManufacturers",
    component: ListManufacturers,
    beforeEnter: checkManufacturerAdmin,
  },
  {
    path: "/models/list",
    name: "ListModels",
    component: ListModels,
    beforeEnter: checkManufacturerAdmin,
  },
  {
    path: "/locations/list/:organisationID",
    name: "ListLocations",
    component: ListLocations,
    beforeEnter: checkOrganisationAdmin,
  },
  {
    path: "/decisionTrees/list/:organisationID",
    name: "ListOrganisationDecisionTrees",
    component: ListDecisionTrees,
    beforeEnter: checkDecisionTreeAdmin,
  },
  // Reports
  {
    path: "/reports/spaces",
    name: "SpaceReport",
    component: SpaceReport,
    beforeEnter: checkOrganisationAdmin,
  },

  // Download links
  {
    path: "/download/space/:organisationID/:locationID/:floorID/:spaceID",
    name: "DownloadSpace",
    component: Download,
  },

  // Authentication
  { path: "/login", name: "Login", component: Login },
  {
    path: "/login-with-password",
    name: "LoginWithPassword",
    component: LoginWithPassword,
  },
  { path: "/register", name: "Register", component: Register },
  { path: "/password-reminder", name: "Reminder", component: Reminder },
  { path: "/verify-email", name: "VerifyEmail", component: VerifyEmail },

  // Responses
  { path: "/auth", name: "AuthNoURL", component: Auth },
  { path: "/auth/:url", name: "Auth", component: Auth },
  { path: "/reset-password/:link", name: "ResetPassword", component: ResetPassword },
  { path: "/validate-email/:link", name: "ValidateEmail", component: ValidateEmail },
  { path: "/magic-link/:link", name: "MagicLink", component: MagicLink },

  // Other links
  { path: "/account", name: "Account", component: Account },
  { path: "/delete-account", name: "DeleteAccount", component: DeleteAccount },
  { path: "/settings", name: "Settings", component: Settings },
  { path: "/select-organisation", name: "SelectOrganisation", component: SelectOrganisation },
  { path: "/favourites", name: "Favourites", component: Favourites },
  { path: "/recent", name: "Recent", component: Recent },
  { path: "/about", name: "About", component: About },
  { path: "/terms", name: "Terms", component: Terms },
  { path: "/contact", name: "Contact", component: Contact },
  { path: "/noaccess", name: "NoAccess", component: NoAccess },
  { path: "/legal", name: "LegalNotices", component: LegalNotices },
  { path: "/introduction", name: "Onboarding", component: Onboarding },
  { path: "/close-me", name: "CloseMe", component: CloseMe },
  { path: "/upgrade", name: "Upgrade", component: Upgrade },
  // { path: "/embed/:path", name: "EmbedPDF", component: EmbedPDF },
  { path: "/404", name: "404", component: NotFound },
  // { path: "/:pathMatch(.*)*", redirect: "/404" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve(async (to, from) => {
  //Redirect user if version is out of date
  if (!checkVersion()) {
    if (to.name == "Upgrade") {
      return true;
    } else {
      return { name: "Upgrade" };
    }
  }

  const alwaysAllowed = [
    "StaticHome",
    "Auth",
    "Onboarding",
    "ValidateEmail",
    "VerifyEmail",
    "MagicLink",
    "ResetPassword",
    "Terms",
    "About",
    "Space",
  ];

  if (!alwaysAllowed.includes(to.name) && isPlatform("cordova") && window.localStorage.getItem("onboardingStatus") != "Complete") {
    return { name: "Onboarding" };
  }

  const userStore = useUserStore();
  const userData = userStore.data;

  setScreen(to.name); // Log screen view event to Google Analytics

  //These pages are always allowed
  if (alwaysAllowed.includes(to.name)) return true;

  if (!userData) return { name: "Home" };

  // If user has registered but not verified email, redirect them to the verify email page
  if (userData.firebaseUser.email && !userData.firebaseUser.emailVerified) return { name: "VerifyEmail" };

  return true;
});

export default router;
