import {
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { db, storage } from "@/composables/firebase";
import { defineStore, storeToRefs } from "pinia";
import { computed, reactive, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { updateTheme } from "@/composables/theme";
import { getDownloadURL, uploadBytes, ref as fbRef, deleteObject, ref } from "firebase/storage";
import { sortObject } from "@/composables/utilities";
import { filterObject, objectToArray } from "../composables/utilities";

export const useOrganisationStore = defineStore("organisationStore", () => {
  const userStore = useUserStore();
  const { user, firebaseUser } = storeToRefs(userStore);

  const data = reactive({
    organisations: {},
    manufacturers: {},
    assetTypes: {},
    documentTypes: {},
    models: {},
    anonymous: {
      organisation: {},
      location: {},
      floor: {},
      space: {},
      decisionTree: {},
      loaded: false,
    },
    selectedOrganisationID: null,
    selectedLocationID: null,
    selectedFloorID: null,
    selectedSpaceID: null,
    selectedDecisionTreeID: null,
    loaded: false,
  });

  let unsubscriptions = [];

  const userOrganisationIDs = computed(() => userStore.data?.user?.organisations || userStore.data?.user?.organisationIDs);
  const loggedIn = computed(() => userStore.data?.firebaseUser?.emailVerified);
  const organisations = computed(() => data.organisations);
  const locations = computed(() => organisation.value?.locations);
  const floors = computed(() => location.value?.floors);
  const spaces = computed(() => floor.value?.spaces);
  const models = computed(() => data.models);
  const manufacturers = computed(() => data.manufacturers);
  const assetTypes = computed(() => data.assetTypes);
  const articles = computed(() => organisation.value?.articles);
  const videos = computed(() => organisation.value?.videos);
  const issues = computed(() => organisation.value?.issues);
  const documentTypes = computed(() => data.documentTypes);
  const filteredOrganisations = computed(() =>
    objectToArray(data.organisations)
      .filter((o) => userStore.data.user?.organisations?.includes(o.id))
      .sort((_a, _b) => {
        const a = _a.name?.toLowerCase();
        const b = _b.name?.toLowerCase();
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      })
  );
  const organisation = computed(() => {
    return organisationAccess.value ? data.organisations?.[data.selectedOrganisationID] : data.anonymous.organisation;
  });
  const location = computed(() =>
    organisationAccess.value ? organisation.value?.locations?.[data.selectedLocationID] : data.anonymous.location
  );
  const floor = computed(() => (organisationAccess.value ? location.value?.floors?.[data.selectedFloorID] : data.anonymous.floor));
  const space = computed(() => (organisationAccess.value ? floor.value?.spaces?.[data.selectedSpaceID] : data.anonymous.space));
  const decisionTree = computed(() =>
    loggedIn.value ? organisation.value?.decisionTrees?.[data.selectedDecisionTreeID] : data.anonymous.decisionTree
  );
  const anonymous = computed(() => data.anonymous);
  const organisationLogo = computed(() => organisation.value?.logo || "/img/logos/logo text black.png");
  const organisationAccess = computed(() => {
    return (loggedIn.value && userStore.user?.organisations?.includes(data.selectedOrganisationID)) || false;
  });

  const userLoaded = () => {
    const checkUser = (resolve) => {
      if (userStore.data.firebaseUser?.isAnonymous || (userStore.data.userLoaded && userStore.data.profileLoaded)) {
        // if (userOrganisationIDs.value) {
        resolve();
      } else {
        setTimeout(checkUser.bind(this, resolve), 50);
      }
    };
    return new Promise(checkUser);
  };

  const getAnonymousSpace = async (organisationID, locationID, floorID, spaceID) => {
    data.anonymous = {};

    if (organisationID) {
      const orgDoc = await getDoc(doc(db, "organisations", organisationID));
      data.anonymous.organisation = { ...orgDoc.data(), articles: {}, videos: {} };
      const artCol = await getDocs(collection(db, `organisations/${organisationID}/articles`));
      artCol.docs.forEach((doc) => {
        data.anonymous.organisation.articles[doc.id] = { ...doc.data(), id: doc.id };
      });
      const vidCol = await getDocs(collection(db, `organisations/${organisationID}/videos`));
      vidCol.docs.forEach((doc) => {
        data.anonymous.organisation.videos[doc.id] = { ...doc.data(), id: doc.id };
      });
    }

    if (locationID) {
      const locDoc = await getDoc(doc(db, `organisations/${organisationID}/locations`, locationID));
      data.anonymous.location = locDoc.data();
    }

    if (floorID) {
      const flrDoc = await getDoc(doc(db, `organisations/${organisationID}/locations/${locationID}/floors`, floorID));
      data.anonymous.floor = flrDoc.data();
    }

    if (spaceID) {
      const spcDoc = await getDoc(doc(db, `organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces`, spaceID));
      const decisionTreeID = spcDoc.data()?.decisionTreeID;
      if (decisionTreeID) {
        const dtDoc = await getDoc(doc(db, `organisations/${organisationID}/decisionTrees`, decisionTreeID));
        const outCol = await getDocs(collection(db, `organisations/${organisationID}/decisionTrees/${decisionTreeID}/outcomes`));
        const destCol = await getDocs(collection(db, `organisations/${organisationID}/decisionTrees/${decisionTreeID}/destinations`));

        data.anonymous.decisionTree = { ...dtDoc.data(), outcomes: {}, destinations: {} };
        outCol.docs.forEach((doc) => {
          data.anonymous.decisionTree.outcomes[doc.id] = { ...doc.data(), id: doc.id };
        });
        destCol.docs.forEach((doc) => {
          data.anonymous.decisionTree.destinations[doc.id] = { ...doc.data(), id: doc.id };
        });
      }
      const issCol = collection(db, `organisations/${organisationID}/issues`);
      const artCol = collection(db, `organisations/${organisationID}/articles`);
      const vidCol = collection(db, `organisations/${organisationID}/videos`);

      const issQuery = query(
        issCol,
        where("locationID", "==", locationID),
        where("floorID", "==", floorID),
        where("spaceID", "==", spaceID),
        where("status", "!=", "success")
      );
      if (data.anonymous?.organisation) {
        data.anonymous.organisation.articles = {};
        data.anonymous.organisation.videos = {};
      }

      const artDocs = await getDocs(artCol);
      const vidDocs = await getDocs(vidCol);

      for (const doc of artDocs.docs) {
        data.anonymous.organisation.articles[doc.id] = doc.data();
      }
      for (const doc of vidDocs.docs) {
        data.anonymous.organisation.videos[doc.id] = doc.data();
      }

      const issDocs = await (
        await getDocs(issQuery)
      ).docs.map((d) => {
        return { ...d.data() };
      });

      data.anonymous.space = spcDoc.data();
      data.anonymous.organisation.issues = issDocs;
    }

    if (data.anonymous.organisation) data.anonymous.organisation.loaded = true;
  };

  const subscribe = async (tableName, destinationObject, { limitByOrganisationID, sortBy, recordLimit, descending }, callback) => {
    const userStore = useUserStore();
    await userStore.getCurrentUser();
    if (limitByOrganisationID) await userLoaded();
    return new Promise((resolve) => {
      //Don't re-subscribe to a table that already has a subscription
      if (unsubscriptions[tableName]) {
        resolve();
        return;
      }

      let col = collection(db, tableName);
      if (limitByOrganisationID === true && !userStore.data?.user?.roles?.includes("admin")) {
        col = query(col, where("id", "in", userOrganisationIDs.value || [""]));
      }
      if (sortBy && recordLimit > 0) {
        col = query(col, orderBy(sortBy, descending ? "desc" : "asc"), limit(recordLimit));
      } else if (sortBy) {
        col = query(col, orderBy(sortBy, descending ? "desc" : "asc"));
      }

      const unsubscribe = onSnapshot(col, (results) => {
        for (const doc of results.docs) {
          try {
            destinationObject[doc.id] = { id: doc.id, ...destinationObject[doc.id], ...doc.data() };
          } catch (error) {
            console.log(tableName, destinationObject, typeof destinationObject, doc);
          }
        }

        //Remove any items that have been removed from the collection
        const existingIDs = results.docs.map((item) => item.id);
        for (const id in destinationObject) {
          if (!existingIDs.includes(id)) delete destinationObject[id];
        }

        if (callback) callback(destinationObject);
        resolve(destinationObject);
      });
      unsubscriptions[tableName] = unsubscribe;
    });
  };

  const getDecisionTrees = async () => {
    await subscribe(`organisations/${organisation.value.id}/decisionTrees`, organisation.value.decisionTrees, {});
    return new Promise((resolve) => {
      const promises = [];
      for (const decisionTreeID in organisation.value.decisionTrees) {
        const decisionTree = organisation.value.decisionTrees[decisionTreeID];
        if (!decisionTree.outcomes) decisionTree.outcomes = {};
        if (!decisionTree.destinations) decisionTree.destinations = {};
        promises.push(
          subscribe(`organisations/${organisation.value.id}/decisionTrees/${decisionTreeID}/outcomes`, decisionTree.outcomes, {})
        );
        promises.push(
          subscribe(`organisations/${organisation.value.id}/decisionTrees/${decisionTreeID}/destinations`, decisionTree.destinations, {})
        );
      }
      Promise.all(promises).then(() => resolve());
    });
  };

  const getOrganisationChecklists = async () => {
    await subscribe(`organisations/${organisation.value.id}/checklists`, organisation.value.checklists, {});
    return;
  };

  const getOrganisationVideos = async () => {
    await subscribe(`organisations/${organisation.value.id}/videos`, organisation.value.videos, {});
    return;
  };

  const getOrganisationArticles = async () => {
    await subscribe(`organisations/${organisation.value.id}/articles`, organisation.value.articles, {});
    return;
  };

  const getChecklists = async (location, floor, space) => {
    if (!space.checklists) space.checklists = {};
    await subscribe(
      `organisations/${organisation.value.id}/locations/${location.id}/floors/${floor.id}/spaces/${space.id}/checklistResponses`,
      space.checklists,
      { orderBy: "date", descending: true, recordLimit: 20 },
      (data) => {
        for (const id in space.checklists) {
          const checklist = space.checklists[id];
          for (const responseID in checklist.responses) {
            const response = checklist.responses[responseID];
            for (const photoIndex in response.photos) {
              const photo = response.photos[photoIndex];
              getDownloadURL(fbRef(storage, photo.path)).then((url) => (photo.url = url));
            }
          }
        }
        space.dueChecklists = filterObject(space.checklists, (c) => {
          return !c.isSubmitted;
        });
        space.completedChecklists = filterObject(space.checklists, (c) => {
          return c.isSubmitted;
        });
      }
    );
    return;
  };

  const getSpaces = async (location, floor) => {
    if (!floor.spaces) floor.spaces = {};
    await subscribe(`organisations/${organisation.value.id}/locations/${location.id}/floors/${floor.id}/spaces`, floor.spaces, {
      sortBy: "name",
    });
    return new Promise((resolve) => {
      const promises = [];
      for (const spaceID in floor.spaces) {
        const space = floor.spaces[spaceID];
        if (!space.pano) space.pano = {};
        if (!space.modelIDs) space.modelIDs = [];
        if (!space.documents) space.documents = [];
        if (!space.featureIDs) space.featureIDs = [];
        if (!space.modelIDs) space.modelIDs = [];
        if (!space.modelDocuments) space.modelDocuments = [];

        if (space.pano?.path)
          try {
            getDownloadURL(fbRef(storage, space.pano.path)).then((url) => (space.pano.url = url));
          } catch (error) {
            console.log(error);
          }

        if (space.devices) {
          space.modelDocuments = [];
          for (const device of space.devices) {
            const model = data.models[device.modelID];
            if (model?.documents) space.modelDocuments = space.modelDocuments.concat(model.documents);
          }
        }

        if (space.photos && space.photos.length > 0) {
          try {
            const photoRef = fbRef(storage, space.photos[0].path);
            space.featurePhotoPath = space.photos[0].path;
            getDownloadURL(photoRef).then((url) => (space.featurePhotoURL = url));
          } catch (error) {
            console.log(error);
          }
        } else {
          space.featurePhotoURL = location.featurePhotoURL;
          space.featurePhotoPath = location.featurePhotoPath || null;
        }

        space.photos?.forEach(async (photo) => {
          try {
            const photoRef = fbRef(storage, photo.path);
            getDownloadURL(photoRef)
              .then((url) => (photo.url = url))
              .catch((error) => console.log(error));
          } catch (error) {
            console.log(error);
          }
        });

        promises.push(getChecklists(location, floor, space));
      }
      Promise.all(promises).then(() => resolve());
    });
  };

  const getFloors = async (location) => {
    if (!location.floors) location.floors = {};
    await subscribe(`organisations/${organisation.value.id}/locations/${location.id}/floors`, location.floors, {});
    return new Promise((resolve) => {
      const promises = [];
      for (const floorID in location.floors) {
        const floor = location.floors[floorID];
        promises.push(getSpaces(location, floor));
      }
      Promise.all(promises).then(() => resolve());
    });
  };

  const getOrganisationData = async () => {
    await subscribe(`organisations/${organisation.value.id}/locations`, organisation.value.locations, {});
    return new Promise((resolve) => {
      const promises = [];
      for (const locationID in organisation.value.locations) {
        const location = organisation.value.locations[locationID];
        // Clean up location
        if (!location.address) location.address = {};
        if (!location.contact) location.contact = {};
        if (!location.wifi) location.wifi = {};

        // Get photos
        location.featurePhotoURL = organisation.value?.theme?.background || organisationLogo.value;
        if (location.photos) {
          for (const photo of location.photos) {
            const storageRef = fbRef(storage, photo.path);
            getDownloadURL(storageRef).then((url) => (photo.url = url));
            if (!location.featurePhotoURL) {
              location.featurePhotoURL = photo.url;
              location.featurePhotoPath = photo.path;
            }
          }
        }
        promises.push(getFloors(location));
      }
      try {
        getDownloadURL(ref(storage, organisation.value.logoPath)).then((url) => (organisation.value.logo = url));
      } catch (error) {
        console.log("Error getting organisation logo", organisation.value, data.selectedOrganisationID);
      }
      promises.push(getDecisionTrees());
      promises.push(getOrganisationChecklists());
      promises.push(getOrganisationArticles());
      promises.push(getOrganisationVideos());
      Promise.all(promises).then(() => resolve());
    });
  };

  const initialise = async (organisationID, locationID, floorID, spaceID, decisionTreeID) => {
    if (organisationID) data.selectedOrganisationID = organisationID;
    if (locationID) data.selectedLocationID = locationID;
    if (floorID) data.selectedFloorID = floorID;
    if (spaceID) data.selectedSpaceID = spaceID;
    if (decisionTreeID) data.selectedDecisionTreeID = decisionTreeID;

    const userStore = useUserStore();
    await userLoaded();

    if (!data.selectedOrganisationID) data.selectedOrganisationID = userStore.data.profile.currentOrganisationID;

    subscribe("models", data.models, {});
    subscribe("assetTypes", data.assetTypes, {});
    subscribe("manufacturers", data.manufacturers, {});
    subscribe("documentTypes", data.documentTypes, {});
    if (organisationID) {
      data.selectedOrganisationID = organisationID;
    }

    if (loggedIn.value) {
      await subscribe("organisations", data.organisations, { limitByOrganisationID: true });
      if (!data.selectedOrganisationID) return;
      //  Clean up organisation
      userStore.setCurrentOrganisationID(data.selectedOrganisationID);
      if (organisation.value) {
        if (!organisation.value.address) organisation.value.address = {};
        if (!organisation.value.theme) organisation.value.theme = {};
        if (organisation.value.logoPath) organisation.value.logo = await getDownloadURL(ref(storage, organisation.value.logoPath));
        if (!organisation.value.locations) organisation.value.locations = {};
        if (!organisation.value.articles) organisation.value.articles = {};
        if (!organisation.value.videos) organisation.value.videos = {};
        if (!organisation.value.beacons) organisation.value.beacons = {};
        if (!organisation.value.issues) organisation.value.issues = {};
        if (!organisation.value.decisionTrees) organisation.value.decisionTrees = {};
        if (!organisation.value.checklists) organisation.value.checklists = {};

        console.log("Getting org data");
        await getOrganisationData();
        organisation.value.loaded = true;
      }
    } else {
      console.log("Need anonymous");
      await getAnonymousSpace(organisationID, locationID, floorID, spaceID);
    }
  };

  const flush = async () => {
    data.organisations = {};
    data.manufacturers = {};
    data.assetTypes = {};
    data.models = {};
    for (const table in unsubscriptions) {
      unsubscriptions[table]();
    }
    unsubscriptions = {};
  };

  const getUbiAuthKey = async () => {
    if (!(space.value?.ubi_id && organisation?.value.ubi_id)) return;

    const response = await fetch(`${process.env.VUE_APP_UBI_BASE_URL}/auth/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        client_id: organisation.value?.ubiClientID,
        client_secret: organisation.value?.ubiClientSecret,
        grant_type: "client_credentials",
      }),
    });
    const data = await response.json();
    return data.access_token;
  };

  const updateSpaceOccupancy = async () => {
    try {
      const auth_key = await getUbiAuthKey();
      if (!auth_key || !space.value?.ubi_id) {
        if (space.value?.currentOccupancy) delete space.value.currentOccupancy;
        return;
      }

      const url = `${process.env.VUE_APP_UBI_BASE_URL}/organizations/${organisation.value.ubi_id}/current-occupancy?locationIds=${space.value.ubi_id}`;
      const response = await fetch(url, {
        method: "GET",
        headers: { Authorization: `Bearer ${auth_key}` },
      });
      const data = await response.json();
      if (space.value) space.value.currentOccupancy = data.length > 0 ? data[0].currentOccupancy : null;
    } catch (error) {
      console.log("Updating space occupancy", error);
    }
  };

  const addOrganisation = async () => {
    const newOrganisation = await addRecord("organisations", { name: "New Organisation" });
    if (!userStore.user.organisations) userStore.user.organisations = [];
    userStore.user.organisations.push(newOrganisation);
    userStore.saveUser();
    return newOrganisation;
  };

  const addLocation = async () => {
    return await addRecord(`organisations/${organisation.value?.id}/locations`, { name: "New Location" });
  };

  const addFloor = async () => {
    return await addRecord(`organisations/${organisation.value?.id}/locations/${location.value.id}/floors`, { name: "New Floor" });
  };

  const deleteOrganisation = async (organisationID) => {
    console.log("Delete organisation", organisationID);
  };

  const getSpacesForFloor = async (organisationID, locationID, floorID) => {
    return organisations.value[organisationID].locations[locationID].floors[floorID].spaces;
  };

  const deleteLocation = async (organisationID, locationID) => {
    console.log("Delete location", organisationID, locationID);
  };

  const getLogos = () => {
    console.log("Get logo");
    // if (organisations.value?.length > 0)
    //   organisations.value?.forEach(async (organisation) => {
    //     organisation.logo = await getDownloadURL(ref(storage, organisation.logoPath));
    //   });
  };

  const saveRecord = async (tableName, id, data, fieldsToRemove = []) => {
    const saveData = { ...data };

    for (const field of fieldsToRemove) {
      delete saveData[field];
    }
    try {
      const saveDoc = doc(db, tableName, id);
      await setDoc(saveDoc, saveData);
    } catch (error) {
      console.log("Error saving to", tableName, saveData, error);
    }
  };

  const addBackground = async (organisationID, file) => {
    const storageRef = fbRef(storage, `Organisations/${organisation.value.name}/Background/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    organisation.value.theme.backgroundPath = snapshot.metadata.fullPath;
    organisation.value.theme.background = await getDownloadURL(storageRef);
    await saveRecord("organisations", organisationID, organisation.value, [
      "decisionTrees",
      "locations",
      "articles",
      "videos",
      "issues",
      "loaded",
      "imageLoaded",
    ]);
  };

  const addLogo = async (organisationID, file) => {
    const storageRef = fbRef(storage, `Organisations/${organisation.value.name}/Logo/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    organisation.value.logoPath = snapshot.metadata.fullPath;
    organisation.value.logo = await getDownloadURL(storageRef);
    await saveRecord("organisations", organisationID, organisation.value, [
      "decisionTrees",
      "locations",
      "articles",
      "videos",
      "issues",
      "loaded",
      "imageLoaded",
    ]);
  };

  const deleteBackground = async (organisationID) => {
    const fileRef = fbRef(storage, organisation.value.theme?.backgroundPath);
    deleteObject(fileRef);
    organisation.value.theme.backgroundPath = null;
    organisation.value.theme.background = null;
    await saveRecord("organisations", organisationID, organisation.value, [
      "decisionTrees",
      "locations",
      "articles",
      "videos",
      "issues",
      "loaded",
      "imageLoaded",
    ]);
  };

  const deleteLogo = async (organisationID) => {
    const fileRef = fbRef(storage, organisation.value.logoPath);
    deleteObject(fileRef);
    organisation.value.logoPath = null;
    organisation.value.logo = null;
    await saveRecord("organisations", organisationID, organisation.value, [
      "decisionTrees",
      "locations",
      "articles",
      "videos",
      "issues",
      "loaded",
      "imageLoaded",
    ]);
  };

  const deleteOrganisationRecord = (tableName, record) => {
    organisation.value[tableName] = organisation.value[tableName].filter((item) => item !== record);
  };

  const addRecord = async (path, record) => {
    const colRef = collection(db, path);
    const result = await addDoc(colRef, record);
    return result.id;
  };

  const deleteRecord = async (path, id) => {
    const docRef = doc(db, path, id);
    await deleteDoc(docRef);
  };

  const addLocationPhoto = async (file) => {
    const storageRef = fbRef(storage, `Organisations/${organisation.value.name}/Locations/${location.value.name}/Photos/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    if (!location.value.photos) location.value.photos = [];
    location.value.photos.push({ path: snapshot.metadata.fullPath, url: url, index: location.value.photos.length });
    await saveRecord(`organisations/${organisation.value.id}/locations`, location.value.id, location.value, ["floors"]);
  };

  const deleteLocationPhoto = async (photo) => {
    location.value.photos = location.value.photos.filter((p) => p != photo);
    for (const [photo, i] in location.value.photos.entries()) {
      photo.index = i;
    }
    const storageRef = fbRef(storage, photo.path);
    await deleteObject(storageRef);
    await saveRecord(`organisations/${organisation.value.id}/locations`, location.value.id, location.value);
  };

  const addSpacePhoto = async (file) => {
    const now = new Date().toISOString();

    const storageRef = fbRef(
      storage,
      `Organisations/${organisation.value.name}/Locations/${location.value.name}/Floors/${floor.value.name}/Spaces/${space.value.name}/Photos/${file.name}-${now}`
    );

    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    console.log(url);
    if (!space.value.photos) space.value.photos = [];
    space.value.photos.push({ path: snapshot.metadata.fullPath, url: url, index: space.value.photos.length });
    await saveRecord(
      `organisations/${organisation.value.id}/locations/${location.value.id}/floors/${floor.value.id}/spaces`,
      space.value.id,
      space.value
    );
  };

  const addChecklistTaskPhoto = async (checklist, task, file) => {
    const now = new Date().toISOString();

    const storageRef = fbRef(
      storage,
      `Organisations/${organisation.value.name}/Locations/${location.value.name}/Floors/${floor.value.name}/Spaces/${space.value.name}/Checklists/${checklist.value.id}/Tasks/${file.name}-${now}`
    );

    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    if (!task.photos) task.photos = [];
    task.photos.push({ path: snapshot.metadata.fullPath, url: url });
  };

  const addSpacePanorama = async (file) => {
    const storageRef = fbRef(
      storage,
      `Organisations/${organisation.value.name}/Locations/${location.value.name}/Floors/${floor.value.name}/Spaces/${space.value.name}/Panorama/${file.name}`
    );
    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    space.value.pano = { ...space.value?.pano, path: snapshot.metadata.fullPath, url: url };
    await saveRecord(
      `organisations/${organisation.value.id}/locations/${location.value.id}/floors/${floor.value.id}/spaces`,
      space.value.id,
      space.value
    );
  };

  const deleteSpacePanorama = async () => {
    try {
      const storageRef = fbRef(storage, space.value.pano.path);
      await deleteObject(storageRef);
    } catch (error) {
      console.log(error);
    }
    space.value.pano = {};
    await saveRecord(
      `organisations/${organisation.value.id}/locations/${location.value.id}/floors/${floor.value.id}/spaces`,
      space.value.id,
      space.value
    );
  };

  const addPanoramaHotspot = (loc) => {
    const newHotspot = {
      id: self.crypto.randomUUID(),
      pitch: loc[0],
      yaw: loc[1],
      text: "New hotspot",
      type: "info",
      hotspotType: "device",
      visible: true,
    };
    if (!space.value.pano.hotspots) space.value.pano.hotspots = [];
    space.value.pano.hotspots.push(newHotspot);
    return newHotspot;
  };

  const addDevicePhoto = async (device, file) => {
    const now = new Date().toISOString();

    const storageRef = fbRef(
      storage,
      `Organisations/${organisation.value.name}/Locations/${location.value.name}/Floors/${floor.value.name}/Spaces/${space.value.name}/Photos/Devices/${device.name}-${now}`
    );

    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    space.value.devices.find((d) => d.id == device.id).photo = { path: snapshot.metadata.fullPath, url: url };
    await saveRecord(
      `organisations/${organisation.value.id}/locations/${location.value.id}/floors/${floor.value.id}/spaces`,
      space.value.id,
      space.value
    );
  };

  const addModelPhoto = async (model, file) => {
    const now = new Date().toISOString();

    const storageRef = fbRef(storage, `Models/${model.value.name}-${now}`);

    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    model.value.photo = { path: snapshot.metadata.fullPath, url: url };
    await saveRecord(`models`, model.value.id, model.value);
  };

  const deleteModelPhoto = async (model) => {
    const storageRef = fbRef(storage, model.value?.photo?.path);
    delete model.value.photo;
    await deleteObject(storageRef);
    await saveRecord(`models`, model.value.id, model.value);
  };

  const deleteDevicePhoto = async (device) => {
    const deviceObject = space.value.devices.find((d) => d.id == device.id);
    const storageRef = fbRef(storage, deviceObject.photo.path);
    delete deviceObject.photo;
    await deleteObject(storageRef);
    await saveRecord(
      `organisations/${organisation.value.id}/locations/${location.value.id}/floors/${floor.value.id}/spaces`,
      space.value.id,
      space.value
    );
  };

  const addSpaceDocument = async (file) => {
    const storageRef = fbRef(
      storage,
      `Organisations/${organisation.value.name}/Locations/${location.value.name}/Floors/${floor.value.name}/Spaces/${space.value.name}/Documents/${file.name}`
    );
    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    if (!space.value.documents) space.value.documents = [];
    space.value.documents.push({ path: snapshot.metadata.fullPath, url: url, description: file.name });
    await saveRecord(
      `organisations/${organisation.value.id}/locations/${location.value.id}/floors/${floor.value.id}/spaces`,
      space.value.id,
      space.value
    );
  };

  const addTreeDocument = async (file) => {
    const storageRef = fbRef(
      storage,
      `Organisations/${organisation.value.name}/DecisionTrees/${decisionTree.value.title}/PDFs/${file.name}`
    );
    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    if (!decisionTree.value.documents) decisionTree.value.documents = [];
    decisionTree.value.documents.push({ path: snapshot.metadata.fullPath, url: url, documentTypeID: null, description: file.name });
    delete decisionTree.value.destinations;
    delete decisionTree.value.outcomes;
    delete decisionTree.value.destinationsLoaded;
    delete decisionTree.value.outcomesLoaded;
    delete decisionTree.value.loaded;
    await saveRecord(`organisations/${organisation.value.id}/decisionTrees`, decisionTree.value.id, decisionTree.value);
    return { path: snapshot.metadata };
  };

  const addModelDocument = async (file, model) => {
    const storageRef = fbRef(storage, `Models/${model.id}/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    if (!model.documents) model.documents = [];
    model.documents.push({ path: snapshot.metadata.fullPath, url: url, documentTypeID: null, description: file.name });
    await saveRecord(`models`, model.id, model);
  };

  const deleteSpacePhoto = async (photo) => {
    space.value.photos = space.value.photos.filter((p) => p != photo);
    for (const [photo, i] in space.value.photos.entries()) {
      photo.index = i;
    }
    const storageRef = fbRef(storage, photo.path);
    await deleteObject(storageRef);
    await saveRecord(
      `organisations/${organisation.value.id}/locations/${location.value.id}/floors/${floor.value.id}/spaces`,
      space.value.id,
      space.value
    );
  };

  const deleteModel = async (modelID) => {
    await deleteRecord(`models`, modelID);
  };

  const deleteSpaceDocument = async (document) => {
    space.value.documents = space.value.documents.filter((p) => p != document);
    await deleteFile(document.path);
    await saveRecord(
      `organisations/${organisation.value.id}/locations/${location.value.id}/floors/${floor.value.id}/spaces`,
      space.value.id,
      space.value
    );
  };

  //   const getSpaceDetails = async (organisationID, locationID, floorID, spaceID) => {
  //     const space = await getDoc(doc(db, `organisations/${organisationID}/locations/${locationID}/floors/${floorID}/spaces`, spaceID));
  //     const floor = await getDoc(doc(db, `organisations/${organisationID}/locations/${locationID}/floors`, floorID));
  //     const location = await getDoc(doc(db, `organisations/${organisationID}/locations`, locationID));
  //     const organisation = await getDoc(doc(db, `organisations`, organisationID));
  //     return { space: space.data(), floor: floor.data(), location: location.data(), organisation: organisation.data() };
  //   };

  const addIssue = async () => {
    return await addRecord(`organisations/${organisation.value?.id}/issues`, {
      created: new Date(),
      status: "warning",
      title: "New Issue",
      locationID: data.selectedLocationID,
      floorID: data.selectedFloorID,
      spaceID: data.selectedSpaceID,
    });
  };

  const addChecklist = async () => {
    return await addRecord(`organisations/${organisation.value?.id}/checklists`, {
      name: "New checklist",
      frequency: "weekly",
    });
  };

  const deleteFile = async (path) => {
    const storageRef = fbRef(storage, path);
    await deleteObject(storageRef);
  };

  const getSpaceReport = async () => {
    const orgs = await getDocs(collection(db, "organisations"));
    const orgData = orgs.docs.map((o) => o.data());
    for (const o of orgData) {
      const locations = await getDocs(collection(db, `organisations/${o.id}/locations`));
      o.locations = locations.docs.map((l) => l.data());
      for (const l of o.locations) {
        const floors = await getDocs(collection(db, `organisations/${o.id}/locations/${l.id}/floors`));
        l.floors = floors.docs.map((f) => f.data());
        for (const f of l.floors) {
          const spaces = await getDocs(collection(db, `organisations/${o.id}/locations/${l.id}/floors/${f.id}/spaces`));
          f.spaces = spaces.docs.map((s) => s.data());
        }
      }
    }
    return orgData;
  };

  const cloneTree = async (tree, organisationID) => {
    const doc = await addDoc(collection(db, `organisations/${organisationID}/decisionTrees`), { title: `Copy of ${tree.title}` });
    const outcomeCollection = await getDocs(collection(db, `organisations/${organisationID}/decisionTrees/${tree.id}/outcomes`));
    const destinationCollection = await getDocs(collection(db, `organisations/${organisationID}/decisionTrees/${tree.id}/destinations`));
    const treeID = doc.id;
    outcomeCollection.docs.forEach((o) =>
      addDoc(collection(db, `organisations/${organisationID}/decisionTrees/${treeID}/outcomes`), o.data())
    );
    destinationCollection.docs.forEach(async (d) => {
      const destDoc = await addDoc(collection(db, `organisations/${organisationID}/decisionTrees/${treeID}/destinations`), d.data());
      if (d.id == tree.rootID)
        saveRecord(`organisations/${organisationID}/decisionTrees`, treeID, { rootID: destDoc.id, title: `Copy of ${tree.title}` });
    });
  };

  const deleteTree = async (organisationID, treeID) => {
    const treeDoc = await getDoc(doc(db, `organisations/${organisationID}/decisionTrees`, treeID));
    const outcomeCollection = await getDocs(collection(db, `organisations/${organisationID}/decisionTrees/${treeID}/outcomes`));
    const destinationCollection = await getDocs(collection(db, `organisations/${organisationID}/decisionTrees/${treeID}/destinations`));
    outcomeCollection.docs.map((o) => deleteDoc(o.ref));
    destinationCollection.docs.map((d) => deleteDoc(d.ref));
    deleteDoc(treeDoc.ref);
  };

  const updateEquipment = async (location, force) => {
    const floors = await getDocs(
      collection(db, `organisations/${data.selectedOrganisationID}/locations/${data.selectedLocationID}/floors`)
    );

    floors.docs.forEach(async (floorDoc) => {
      const floor = floorDoc.data();
      const spaces = await getDocs(
        collection(db, `organisations/${data.selectedOrganisationID}/locations/${data.selectedLocationID}/floors/${floor.id}/spaces`)
      );
      spaces.docs.forEach(async (spaceDoc) => {
        const space = spaceDoc.data();
        if (space.devices) {
          for (const device of space.devices) {
            console.log(space, device);
            if (location.defaultInstallerName && (!device.installer || force)) device.installer = location.defaultInstallerName;
            if (location.defaultInstallDate && (!device.installDate || force)) device.installDate = location.defaultInstallDate;
            if (location.defaultWarrantyExpirationDate && (!device.warrantyExpirationDate || force))
              device.warrantyExpirationDate = location.defaultWarrantyExpirationDate;
          }
        }
        saveRecord(
          `organisations/${data.selectedOrganisationID}/locations/${data.selectedLocationID}/floors/${floor.id}/spaces`,
          space.id,
          space
        );
      });
    });
  };

  const updateSpaces = async (location, force) => {
    const floors = await getDocs(
      collection(db, `organisations/${data.selectedOrganisationID}/locations/${data.selectedLocationID}/floors`)
    );

    floors.docs.forEach(async (floorDoc) => {
      const floor = floorDoc.data();
      const spaces = await getDocs(
        collection(db, `organisations/${data.selectedOrganisationID}/locations/${data.selectedLocationID}/floors/${floor.id}/spaces`)
      );
      spaces.docs.forEach(async (spaceDoc) => {
        const space = spaceDoc.data();
        if (location.defaultTypeformID && (!space.typeformID || force)) space.typeformID = location.defaultTypeformID;
        if (location.defaultDecisionTreeID && (!space.decisionTreeID || force)) space.decisionTreeID = location.defaultDecisionTreeID;
        if (location.defaultSpaceTypeID && (!space.spaceTypeID || force)) space.spaceTypeID = location.defaultSpaceTypeID;
        if (location.defaultCapacity && (!space.capacity || force)) space.capacity = location.defaultCapacity;
        if (location.defaultChecklistID && (!space.checklistID || force)) space.checklistID = location.defaultChecklistID;
        saveRecord(
          `organisations/${data.selectedOrganisationID}/locations/${data.selectedLocationID}/floors/${floor.id}/spaces`,
          space.id,
          space
        );
      });
    });
  };

  return {
    organisations,
    locations,
    floors,
    spaces,
    models,
    issues,
    manufacturers,
    assetTypes,
    articles,
    videos,
    data,
    organisation,
    location,
    decisionTree,
    floor,
    space,
    organisationLogo,
    userOrganisationIDs,
    documentTypes,
    filteredOrganisations,
    saveRecord,
    flush,
    addOrganisation,
    deleteOrganisation,
    deleteModel,
    // saveOrganisation,
    getLogos,
    addBackground,
    deleteBackground,
    addLogo,
    deleteLogo,
    deleteOrganisationRecord,
    addRecord,
    deleteRecord,
    addLocation,
    initialise,
    deleteLocation,
    addLocationPhoto,
    deleteLocationPhoto,
    addFloor,
    addSpacePhoto,
    addChecklist,
    addChecklistTaskPhoto,
    addSpaceDocument,
    deleteSpacePhoto,
    deleteSpaceDocument,
    addModelDocument,
    addSpacePanorama,
    deleteSpacePanorama,
    addPanoramaHotspot,
    // getSpaceDetails,
    getAnonymousSpace,
    getSpacesForFloor,
    addIssue,
    userLoaded,
    updateSpaceOccupancy,
    addDevicePhoto,
    deleteDevicePhoto,
    addModelPhoto,
    deleteModelPhoto,
    addTreeDocument,
    deleteFile,
    updateEquipment,
    updateSpaces,
    // test,
    anonymous,
    getSpaceReport,
    cloneTree,
    deleteTree,
  };
});
